import React from 'react';
import { Section, SubSection, TeamProf } from '../../Elements/LayoutElements.js';
import { SectionTitle} from '../../Elements/UIElements.js';


const Team =[{
    Name: "Aswin Bandaru",
    Title: "Cofounder",
    ImgURL: "https://mzstaticstorage.blob.core.windows.net/mz-container-static-content/Aswin.png",
    LinkedInURL: "https://www.linkedin.com/in/ash-bandaru/"
},
{
    Name: "Satya Gopisetti",
    Title: "Cofounder",
    ImgURL: "https://mzstaticstorage.blob.core.windows.net/mz-container-static-content/Deepthi.png",
    LinkedInURL: "https://www.linkedin.com/in/satya-deepthi-gopisetti-172b1b85/"
}]

const AdvisoryBoard =[{
    Name: "Richard Seline",
    Title: "Advisory Board Member",
    ImgURL: "https://mzstaticstorage.blob.core.windows.net/mz-container-static-content/RSeline.png",
    LinkedInURL: "https://www.linkedin.com/in/richardseline"
},
{
    Name: "Eric Fishaut",
    Title: "Advisory Board Member",
    ImgURL: "https://mzstaticstorage.blob.core.windows.net/mz-container-static-content/EFishaut.png",
    LinkedInURL: "https://www.linkedin.com/in/eric-fishhaut"
}]

const OurTeam= () =>{
    return(
        
        <Section>
            <SectionTitle>Our Team</SectionTitle>
            <SubSection>
                {Team.map((Obj,i)=>{
                    return(
                        <TeamProf TeamMemObj = {Obj} key={i}/>
                    )
                })}
            </SubSection>
            <SectionTitle>Advisory Board</SectionTitle>
            <SubSection>
                {AdvisoryBoard.map((Obj,i)=>{
                    return(
                        <TeamProf TeamMemObj = {Obj} key={i}/>
                    )
                })}
            </SubSection>
        </Section>
        )   
    }
    
    export default OurTeam