import React from 'react';
import {SubSection,Section, Card, PCard} from '../../Elements/LayoutElements.js';
import { SectionTitle} from '../../Elements/UIElements.js';
import CrushedPlasticBottles from '../../../Images/CrushedPlasticBottles.svg';
import Ecofriendly from '../../../Images/Ecofriendly.svg';
import healthheart from '../../../Images/heart.svg';
import FoundersInstitute from '../../../Images/FoundersInstitute.svg';
import Microsoft from '../../../Images/MicrosoftLogo.svg';

const OurInPSection = () =>{
    return(
        <Section>
        <SectionTitle>Our Impact</SectionTitle>
          <SubSection>
              <Card CardImg={CrushedPlasticBottles} ImgAlt="CrushedPlasticBottles" CardTxt={"Eliminating plastic pollution"} />
              <Card CardImg={Ecofriendly} ImgAlt="ecofriendly" CardTxt={"Reducing climate change"} />
              <Card CardImg={healthheart} ImgAlt="healthheart" CardTxt={"Eliminating microplastics in food"} />
          </SubSection>
        <SectionTitle>Our Partners</SectionTitle>
          <SubSection>
              <PCard CardImg={Microsoft} ImgAlt="Microsoft" cbgrnd={"#F5F5F5"} CardTitle={"Microsoft"} $pcardimage="true"/> 
              <PCard CardImg={FoundersInstitute} ImgAlt="FoundersInstitute" cbgrnd={"#F5F5F5"} CardTitle={"Founder's Institute"} $pcardimage="true"/>
          </SubSection>
        </Section>
        )   
    }
    
    export default OurInPSection