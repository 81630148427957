import React from 'react';
import {Section, SubSection, FlexDiv, InBDiv} from '../Elements/LayoutElements';
import { Img, Para } from '../Elements/UIElements';
import  facebook from '../../Images/facebook.svg';
import  twitter from '../../Images/twitter.svg';
import instagram from '../../Images/Instagram.svg'
import  linkedin from '../../Images/linkedin.svg';

const TxtStyle = {color: "#ffffff", padding: "0",  margin: "0 5%"};
const PStyle ={...TxtStyle, $fontsize: "14px", fontWeight: "bold", textAlign: "left" };
const TextStyle = {...TxtStyle, minWidth: "120px"};

const Footer = () =>{
    return(
          <Section $bgcolor="#059950" style={{margin: "0", bottom: 0}}>
            <SubSection $bgcolor="#059950" style={{justifyContent: "space-around", margin: "2vh 0 2vh 0"}}>
                <FlexDiv $bgcolor="#059950" style={{justifyContent: "space-around", flexDirection: "initial"}}>
                    <a href="https://www.facebook.com/missionzero.green"><Img src={facebook} alt="facebook" /></a>
                    <a href="https://www.twitter.com/Mizerogreen"><Img src={twitter} alt="twitter" /></a>
                    <a href="https://www.instagram.com/missionzero.green"><Img src={instagram} alt="instagram" /></a>
                    <a href="https://www.linkedin.com/company/mizero"><Img src={linkedin} alt="linkedin" /></a>
                </FlexDiv>
                <FlexDiv style={{justifyContent: "space-around", gap: `${window.innerWidth < 500 ? "0" : "5vw"}`,flexDirection: "initial"}}>
                    <InBDiv style={{minWidth: "150px", margin: "0", padding: "0"}}>
                        <Para style={PStyle}>Address</Para>
                        <Para $talign="left" $fontsize="11px" style={TxtStyle}>
                            1801 Main St, suite 1300 <br />Houston TX USA 77002
                        </Para>
                    </InBDiv>
                    <InBDiv style={{minWidth: "180px", margin: "0", padding: "0"}}>
                    <Para style={PStyle}>Contact</Para>
                        <Para $talign="left" $fontsize="11px" style={TxtStyle}>
                            Email: info@missionzero.green <br />Phone: +1 832 974 0050 
                        </Para>
                    </InBDiv>
                </FlexDiv>
            </SubSection >
            <SubSection $bgcolor="#059950" style={{justifyContent: "space-around", margin: "0", gap: `${window.innerWidth < 500 ? "0" : "5vw"}`}}>
                <FlexDiv style={{justifyContent: "space-around", flexDirection: "initial"}}>
                <Para $talign="center" $fontsize="11px" style={TextStyle}>
                    Copyright © 2024
                </Para>
                <Para $talign="center" $fontsize="11px" style={TextStyle}>
                    All Rights Reserved
                </Para>
                </FlexDiv>
                <FlexDiv style={{justifyContent: "space-around", flexDirection: "initial"}}>
                <Para $talign="center" $fontsize="11px" style={TextStyle}>
                    Terms & Services
                </Para>
                <Para $talign="center" $fontsize="11px" style={TextStyle}>
                    Privacy Policy
                </Para>
                </FlexDiv>
            </SubSection>
    </ Section>
        )   
    }
    
    export default Footer