//Adding a line to initiate retracking
import { useState, useEffect } from 'react';
import HeroSection from '../Layouts/Home/HeroSection.js';
import { PopUp } from '../Utils/PopUp.js';
import HWSection from '../Layouts/Home/HWSection.js';
import OurInPSection from '../Layouts/Home/OurInPSection.js';
import SolSection from '../Layouts/Home/SolSection.js';
import {Page} from '../Elements/LayoutElements.js';

const HomePage = () =>{
    const [divDisp, setdivDisp] = useState(false);

    const changePopUp = () =>{
        setdivDisp(!divDisp)
    }
  
    useEffect((divDisp)=>{
        const LetItPop = setTimeout(()=>{
            setdivDisp(!divDisp)
        }, 1000)
  
        return () => clearTimeout(LetItPop)
    }, [])
return(
    <Page>
        {/* <HeroSection /> */}
        <PopUp divDisp={divDisp} changePopUp={changePopUp}/>
        <SolSection divDisp={divDisp} changePopUp={changePopUp}/>
        <HWSection />
        <OurInPSection />
    </Page>
)}

export default HomePage