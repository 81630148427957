import React from 'react';
import { ChildDiv } from '../../Elements/LayoutElements.js';
import {SubSection,Section} from '../../Elements/LayoutElements.js';
import {SectionTitle, RLImg, Description} from '../../Elements/UIElements.js';

//"https://mzstaticstorage.blob.core.windows.net/mz-container-static-content/WebAssets/green_fields.png"

const OurStory = () =>{
        return(
            <Section>
            <SectionTitle>Our Story</SectionTitle>
            <SubSection>
              <ChildDiv>
              <Description>
              Mission Zero's founder Aswin was born and raised the countryside (coastal Andhra Pradesh) of India. He grew up surrounded by the pristine greenery. 
              Growing up he witnessed many daily practices that are green and sustainable. Later on he lived in the USA and Denmark. 
              Living and meeting people across three continents, his personal transformation journey led him to a greener and zero-waste lifestyle.
              When he visited his home town many years later, he was shocked and deeply touched to see how the urbanization has been polluting the ecosystems.
 Since then, he has been on a journey to make the world more greener. In 2019, he launched Fizzit, a zero waste beverage carbonating ser
vice in Denmark. Due to the COVID-19 pandemic, he returned back to the USA. This marked the birth of Mission Zero in September, 2022. 
            </Description>
            </ChildDiv>
            <ChildDiv>
              <RLImg src={"https://mzstaticstorage.blob.core.windows.net/mz-container-static-content/WebAssets/green_fields.png"} alt="Konaseema Green Fields " />   
              </ChildDiv>
           </SubSection>  
            <SubSection>
              <ChildDiv>
              <RLImg src={"https://mzstaticstorage.blob.core.windows.net/mz-container-static-content/WebAssets/Together.png"} alt="Hands holding together with earth in the middle" /> 
              </ChildDiv>
              <ChildDiv>
              <Description>
              The inspiration for Mission Zero lies in a vision of a sustainable world. If we are to see such a world, change needs to happen on a global level at a complex scale. UN has laid down 17 goals that need to be achieved in order to fulfill the vision of a sustainable world. Obviously, no one person or group can achieve them. It requires the collective effort of all of us. Mission Zero wants to inspire you and be inspired by you, by working together to build a world that we can live and love. Our purpose is to build a sustainable world together with you.
            </Description>  
              </ChildDiv>
           </SubSection>
           </Section>
        
    )   
}

export default OurStory