import React from 'react';
import {Text,Title} from '../../Elements/UIElements.js';
import { Section } from '../../Elements/LayoutElements.js';



const OurVision = () =>{
    return(
        
        <Section $bgcolor="#059950">
            <Title $fontcolor="#ffffff">Our Vision</Title>
            <Text $fontcolor="#ffffff">A sustainable world in which we eat, drink and use things responsibly without waste.</Text>
            <Title $fontcolor="#ffffff">Our Mission</Title>
            <Text $fontcolor="#ffffff">To create a zero waste world by eliminating packaging and plastic waste from food and consumer goods.</Text>
         </Section>
        
            )   
        }
        
        export default OurVision; 