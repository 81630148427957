import styled from 'styled-components';
import {Section, BlockDiv, SubSection} from '../../Elements/LayoutElements.js';
import HeroImage from '../../../Images/HeroImage.svg';
import {GreenButton, Img, Para, SectionTitle } from '../../Elements/UIElements.js';

const HeroSectionImage =styled(Img).attrs((props)=>{
  const $pagewidth = window.innerWidth;
   return { ...props,
    $pagewidth
  }
})`
width: ${({$pagewidth}) => $pagewidth < 500 ? "75vw" : "35vw"}; 
margin: 1vh 1vw; 
`;

export const HeroSectionTitle = styled(SectionTitle)`
`;

export const HeroSectionPara = styled(Para)`
  text-align: left;
  `;

const HeroSection = () =>{
        return(
           <Section>
            <HeroSectionTitle >Eliminate waste from households to commercial built environment</HeroSectionTitle>    
            <SubSection>
              <HeroSectionImage src={HeroImage} alt="image72" />
              <BlockDiv>
              <HeroSectionPara>Smart food logistics infrastructure and solutions leaving zero packaging waste for onsite food service operations</HeroSectionPara>
              <GreenButton>Learn More</GreenButton>
              </BlockDiv>
            </SubSection>
           </Section>
    )   
}

export default HeroSection

