import { useState } from "react";
import { FormInput, TextArea, Para, GreenButton} from './../Elements/UIElements';
import{ BlockDiv, FlexItem, Section, FormSection } from './../Elements/LayoutElements';
import SubmitForm from './../Utils/SubmitForm';
import { UsersAPI } from './../Utils/HTTP/Axios';

const ContactPage = () => {

    const NameArray = [{ "name": "firstName", "type": "text", "placeholder": "First Name" },
    { "name": "lastName", "type": "text", "placeholder": "Last Name" }];

    const ContactInfoArray = [{ "name": "email", "type": "Email", "placeholder": "Email" },
    { "name": "phone", "type": "text", "placeholder": "Phone/Mobile" }];

    const IntArray = [{ "name": "subject", "type": "text", "placeholder": "Subject" }];

    let stateObj = {"message": ""};

    NameArray.map((finp, i) => {
        return (stateObj[finp.name] = "")
    })

    ContactInfoArray.map((finp, i) => {
        return (stateObj[finp.name] = "")
    })

    IntArray.map((finp, i) => {
        return (stateObj[finp.name] = "")
    })

    const [FormData, setFormData] = useState(stateObj);
    const [reply, setReply] = useState({ success: false, data: null, message: null })
    const [request, setRequest] = useState (false)

    const handleChange = (e) => {
        setFormData({ ...FormData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setRequest(true);
        const {subject, message, ...UserData} = FormData;
        let CData = {"communication": {"subject": subject,"message": message}, ...UserData};
        if(FormData.email && FormData.message){
                try{
                    const response = await UsersAPI.post(`/Contact`, CData)
                    setReply(SubmitForm(response))
                } catch (error)
                {
                    console.log(error);
                    setReply(SubmitForm(error))
                }
        } else{
            setReply({success: false, data: null, message: "Check your inputs"})
        }
        setRequest(false)
    }

    return (
            <Section>
                <Para>If you have any questions please contact us and we will respond as soon as possible</Para>
                <FormSection>
                    <FormInput
                        InputArray={NameArray}
                        handleChange={handleChange}
                        FormData={FormData}
                    />
                </FormSection>
                <FormSection>
                    <FormInput
                        InputArray={ContactInfoArray}
                        handleChange={handleChange}
                        FormData={FormData}
                    />
                </FormSection>
                <FormSection>
                    <FormInput
                        InputArray={IntArray}
                        handleChange={handleChange}
                        FormData={FormData}
                    />
                </FormSection>
                <FormSection>
                    <TextArea placeholder="Message" onChange={handleChange}  value={FormData["message"]} name="message"/>
                </FormSection>
                <FlexItem>
                <GreenButton onClick={handleSubmit}>Submit</GreenButton>
                <BlockDiv>
                {reply.message && <Para>{reply.message}</Para>}
                {request && <Para>Please Wait....</Para>}
                </BlockDiv>
                </FlexItem>
            </Section>
    )
}

export default ContactPage
