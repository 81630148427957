
import Axios from "axios";

const APIGateway = "https://mzcoreservice-dev.azurewebsites.net/api";

const RootAPI = Axios.create({
    baseURL: `${APIGateway}`,
    timeout: 10000,
})

const UsersAPI = Axios.create({
    baseURL: `${APIGateway}/website-users`,
    timeout: 10000,
})

const ContentAPI = Axios.create({
    baseURL: `${APIGateway}/content`,
    timeout: 10000,
})
export { RootAPI, UsersAPI, ContentAPI}