import './App.css';
import Footer from './Components/Layouts/Footer';
import AboutComp from './Components/Views/AboutusPage';
import React, { useState } from 'react';
import {Navbar,MobNavLinks} from './Components/Layouts/Navigation';
import {Route, Routes} from 'react-router-dom';
import HomeComp  from './Components/Views/HomePage';
import ContactComp from './Components/Views/ContactPage';
import { Page } from './Components/Elements/LayoutElements';

const RoutesArray =  [
  {"path": "/Contact", "element": <ContactComp />},
  // {"path": "/Blog/:id", "element": <BlogArticle />},
  // {"path": "/Blog", "element": BlogComp},
  // {"path": "/Login", "element": <UserLogin />},
  {"path": "/About", "element":   <AboutComp /> },
  {"path": "/", "element": <HomeComp />    }
];

const App = () => {

  const [$view, setview] = useState(false);

  const handleView =()=>{
    setview(!$view);
}
  return (

<Page>    
      <Navbar
        $view={$view}
        handleView={handleView}
      />
      <MobNavLinks
        $view={$view}
        handleView={handleView}
      />
      <Routes>
        {RoutesArray.map((Obj, i) => {
          return (
            <Route
              key={i}
              path={Obj.path}
              element={Obj.element}
            />
          )
        })}
      </Routes>
    <Footer />
</Page>
  );
}

export default App;
