import styled from "styled-components";
import MenuSandwich from '../../Images/MenuSandwich.svg';
import Cancel from '../../Images/Cancel.svg';
import useWindowWidth from "../Elements/useWindowWidth";
import { Link } from 'react-router-dom';
import Logo1 from '../../Images/Logo.svg';
import { Title, Img } from "../Elements/UIElements";
import { FlexDiv, InFlexDiv } from "../Elements/LayoutElements";


export const NavArray = [{path: "", pathName: "Home", element: `HomeComp`}, {path: "About", pathName: "About", element: `AboutComp`}, {path: "Contact", pathName: "Contact", element: `ContactComp`}];


//Navigation Bar
const NavDiv = styled(FlexDiv)`
        background-color: #ffffff;
        flex-direction: row;
        position: sticky;
        top: 0;
        height: 70px;
        align-items: center;
        justify-content: ${({ $pagewidth }) => $pagewidth < 500 ? "space-between" : "center"};
        gap: 10vw;
`;

const TitleDiv = styled(FlexDiv)`
align-items: center;
justify-content: space-between;
gap: 5vw;
`;

const NavLinksDiv =  styled(InFlexDiv).attrs((props)=>{
let wide = useWindowWidth(window.innerWidth)
 return { ...props,
         $pagewidth: wide}
})`
flex-direction: ${({$pagewidth}) => $pagewidth > 480 ? "row" : "column"};
justify-content: space-evenly;
align-items: center;
`;

const StyledLink = styled(Link)`
        cursor:pointer;
        font-family: Open-Sans;
        text-decoration:none;
`;

const NavLink = styled(StyledLink)`
        margin:2vh 2vw;
        color: #000000;
        font-size: 1.0em;
        letter-spacing: 1px;
        text-decoration: none;
        font-family: Roboto;
        font-weight: 500;        
`;

const LogoLink = styled(StyledLink)`
        width: 64px;
        height: 64px;
`;

const SandwichIcon = styled(Img).attrs(props =>{
    let windowwidth = useWindowWidth(window.innerWidth)
     return { ...props,
            $pagewidth: windowwidth
    }
})`
    margin: 1vh 2vw; 
    width: ${({width, $pagewidth}) => width || ($pagewidth < 500 ? "50%" : "25vw") };
    height: ${({height}) => height || "auto"};
    &:hover {
        cursor: pointer;       
       };
`;

const NavImg = styled.img`
    margin: auto;
`;

const AVImg =  styled(Img)`
        width: 60px;
        height:60px;
        border-radius:50%;// 10.5rem;
`;

const NavEndDiv = styled(InFlexDiv)`
        position: relative;
        right: 2vw;
    //margin-right: 2%; 
`;

const MobileNav = styled(FlexDiv)`
        flex-direction: column;
        position: fixed;
        float: right;
        // display: ${({ $view }) => !$view && 'none'};
        justify-content: center;
        align-items: flex-end;
        // margin-right: 160%;
        width: 40%;
        height: auto;
        top: 64px;
        background:green;
        transform: ${({ $view }) => $view ? 'translateX(60vw)' : 'translateX(100vw)'};
        z-index: 2;
        transition: transform 0.3s ease-in-out;
`

const MobNavLink = styled(StyledLink)`
        border: 2px;
        font-size: 2em;
        margin: 2vw 2vh;
        color: white; 
        height: 10vh;
        display: flex;
        flex-wrap: wrap;
        align-content: center
        text-align: center;
        align-items: center;
`;

const NavLinks = () =>{
        return (
               
                <NavLinksDiv>
                        {NavArray.map((NavObj, i)=>{
                                return (
                                        <NavLink to={`/${NavObj.path}`} key={i}>{NavObj.pathName}</NavLink>
                                )
                        })}
                </NavLinksDiv>
                
        )
};

const NavEnd = ({$view, handleView, $pagewidth})=>{
        let     point = "/login",
                srce =  MenuSandwich,                //image13; 
                clickfunc = null;
        if ($pagewidth < 500) {
                point = "";
                clickfunc = handleView;
                srce = MenuSandwich;
                if($view){
                        srce = Cancel;
                }
        }
        return (
                <NavEndDiv >
                        {$pagewidth < 500 ? (<SandwichIcon onClick={clickfunc} src = {srce} width="54px"alt="SandwichIcon"/> ) :
                        (<StyledLink href={point} >
                                <AVImg onClick={clickfunc} src = {srce} width="54px"   alt="cancel"/>
                        </StyledLink>)
                        }                         
                </NavEndDiv>
        )
}

export const Navbar = ({view, handleView}) =>{
        let wide = useWindowWidth(window.innerWidth),
        fleft =  "5px";
        if(wide < 500) {
                fleft = (0.5*wide - 32);
        }
          
  return(  
          <NavDiv>
                  <TitleDiv>
                          <LogoLink href='/' >
                                  <NavImg src={Logo1} alt="Logo1" />
                          </LogoLink>
                          {wide > 500 && <InFlexDiv>
                                  <Title $fontcolor="#059950">Mission Zero</Title>
                          </InFlexDiv>}
                  </TitleDiv>
                  {wide < 500 && <InFlexDiv>
                          <Title $fontcolor="#059950">Mission Zero</Title>
                  </InFlexDiv>}
                  {wide > 500 ? <NavLinks /> :
                          <NavEnd
                                  view={view}
                                  handleView={handleView}
                                  $pagewidth={wide}
                          />}
          </NavDiv>
    )
} 

export const MobNavLinks = ({$view, handleView}) =>{
        return(
          <MobileNav $view={$view}>
                        {NavArray.map((NavObj, i)=>{
                                return (
                                        <MobNavLink to={`/${NavObj.path}`} onClick={handleView} key={i}>{NavObj.pathName}</MobNavLink>
                                )
                        })}
          </MobileNav>
        )  
  }
  



 





