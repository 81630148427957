import styled from "styled-components";
import useWindowWidth from './useWindowWidth.js';
import LinkedIn from '../../Images/linkedin.svg'

//Basic Divs 
 export const SectionBlock=styled.div`
display: block;
text-align: center;
`;

export const BlockDiv=styled.div`
 display:block;
 text-align: center;
 `;

 export const Section =styled(BlockDiv)`
 align-content:center;
 background-color: ${({$bgcolor})=> $bgcolor && $bgcolor};
 `;

 export const Page =styled(BlockDiv)`
 width: 100%;
 `;

 export const InBDiv =styled.div`
display:inline-block;
background-color: ${({$bgcolor})=> $bgcolor && $bgcolor};
`;

export const ChildDiv =styled(InBDiv).attrs(props =>
        {
            let wide = useWindowWidth(window.innerWidth)
             return { ...props,
                     $pagewidth: wide
            }
        })`
width: ${({$pagewidth}) => $pagewidth < 500 ? "100%" : "35vw" };
overflow: hidden;
overflow-wrap: break-word;
`;

export const InDiv =styled.div`
display: inline;
background-color: ${({$bgcolor})=> $bgcolor && $bgcolor};
`;

export const InFlexDiv =styled.div`
display: inline-flex;
background-color: ${({$bgcolor})=> $bgcolor && $bgcolor};
`;

export const ABSDiv = styled(BlockDiv)`
position: absolute;
left: ${({$lft}) => $lft || "auto"};
right: ${({$rgt}) => $rgt || "auto"};
top: ${({$topp}) => $topp || "auto"};
bottom: ${({$bot}) => $bot || "auto"};
`;

//FlexBox Section

export const FlexDiv=styled.div.attrs((props) =>{
        let wide =  useWindowWidth(window.innerWidth);
        return {
                ...props,
                $pagewidth: wide
        }
})`
background-color: ${({$bgcolor})=> $bgcolor && $bgcolor};
display:flex;
text-align: center;
`;

export const FlexItem = styled(FlexDiv)`
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const SubSection=styled(FlexDiv).attrs(props =>{
        let wide = useWindowWidth(window.innerWidth)
           return { ...props,
                  $pagewidth: wide
          }
        })`
flex-direction: ${({$pagewidth}) => $pagewidth < 500 ?  "column" : "row"};
gap:${({$pagewidth}) => $pagewidth < 500 ?  "10px" : "0"};
background-color: ${({$bgcolor}) => $bgcolor && $bgcolor};
margin:${({$pagewidth}) => $pagewidth < 500 ?  "2vh 2vw" : "5vh 5vw"};
// padding:2vh 2vw;
align-items:stretch;
justify-content: space-around;
`;

export const BGSection = styled(SubSection).attrs(props => {
        let wide = useWindowWidth(window.innerWidth)
         return { ...props,
            $pagewidth: wide
        }
    })`
    background-image: ${({$bground, $pagewidth}) => $pagewidth > 480 && `url(${$bground})`};
    background-repeat: no-repeat;
    // background-position: center;
    background-size: contain;
    justify-content: space-around;
    `;
//--------------------------------------------------------------------------CARD COMPONENTS-----------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //Card Text
  export const CardText = styled.p.attrs(props =>{
        let wide = useWindowWidth(window.innerWidth)
           return { ...props,
                  $pagewidth: wide
          }
        })`
      color: #000;
      text-align: center;
      font-family: ${({$family}) => $family || "Roboto"};
      font-size:${({$pagewidth}) => $pagewidth < 500 ? "1rem" : `calc(1rem + ${1 * 0.005*($pagewidth - 480)}px)`};
      font-style: normal;
      font-weight: 400;
      line-height:22px; 
     margin:1vh 1vw;
      
      `;

const CardImage =styled.img.attrs((props)=>{
let wide = useWindowWidth(window.innerWidth)
 return { ...props,
        $pagewidth:  wide
}
})`
max-width: ${({$width, $pagewidth}) => $width || ($pagewidth < 500 ? "90vw" : "80%") };
margin: ${($pcardimage) => Boolean($pcardimage) ? "2vh 5vw": "2vh 2vw"};
// object-fit:cover;
// object-position:50% 50%;
 `;

 const PCardDiv= styled(FlexDiv)`
 justify-content: space-between;
 align-items: center;
 flex-direction: column;
 border-radius: 1.25rem;
 background-color: ${({$bgcolor})=> $bgcolor && $bgcolor};
 `;

const CardDiv= styled(PCardDiv)`
box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.25);
margin:1vh 1vw;
`;

export const Card = ({CardImg, ImgAlt, CardTxt, cbgrnd, $pcardimage }) =>{
return(
        <CardDiv $bgcolor = {cbgrnd}>
                <CardImage src={CardImg} alt={ImgAlt} $pcardimage={$pcardimage}/>
                {CardTxt && <CardText>{CardTxt}</CardText>}
        </CardDiv>
)}

export const PCard = ({CardTitle, ...cardprops})=>{
        return(
                <PCardDiv>
                        <CardText>{CardTitle}</CardText>
                        <Card {...cardprops}/>
                </PCardDiv>

        )
}

const ProfImage = styled(CardImage)`
border-radius: 50%;
width: 256px;
`;

const PNameText = styled(CardText)`
font-weight: 600;
margin: 0 2vw;
`;

const LinkdImg = styled.img`
width: 64px;
height: 64px;
margin: 0;
`;

export const TeamProf = ({TeamMemObj}) =>{
        return(
                <PCardDiv >
                        <ProfImage src={TeamMemObj.ImgURL} alt={TeamMemObj.Name}/>
                        <PNameText>{TeamMemObj.Name}</PNameText>
                        <CardText>{TeamMemObj.Title}</CardText>
                        <InDiv>
                                <LinkdImg src={LinkedIn} alt="LinkedIn" />
                        </InDiv>
                </PCardDiv>
        )}

export const PopUpDiv = styled(FlexDiv).attrs((props)=>{
        let wide = useWindowWidth(window.innerWidth);
        return {
                ...props,
                $pagewidth:  wide
        }
})`
flex-direction: column;
background-color: #ffffff;
align-items: center;
border: 2px solid #059950;
box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.25);
padding: 2vh 2vw;
border-radius: 5% 5%;
z-index: 999;
position: fixed;
width: ${({$pagewidth}) => $pagewidth < 500 ? "80%": "36%"};
top: 10%;
left: ${({$pagewidth}) => $pagewidth < 500 ? "10%": "32%"};
`;

//-------------------------------------------------------------------CONTACT PAGE LAYOUTS -------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------------------------------------------------

export const FormSection = styled(FlexDiv)`
    flex-direction: ${({ $pagewidth }) => $pagewidth < 500 ? "column" : "row"};
    gap:${({ $pagewidth }) => $pagewidth < 500 ? "10px" : "10vw"};
    background-color: ${({ $bgcolor }) => $bgcolor && $bgcolor};
    justify-content: ${({ $pagewidth }) => $pagewidth < 500 ? "space-around" : "center"};
    padding:1vh 1vw;
    margin: 0;
    align-items:center;
    `;

