import { useState} from "react";
import { UsersAPI } from "./HTTP/Axios";
import { PopUpDiv, ABSDiv, FlexItem, FlexDiv } from "../Elements/LayoutElements";
import  SubmitForm from './SubmitForm';
import { Para, StyledButton, FormInput } from "../Elements/UIElements";

const EmailArray =[ {"name": "email","type": "Email", "placeholder": "Email"}];
const NameArray= [{"name": "firstName","type": "text", "placeholder": "First Name"}, 
{"name": "lastName","type": "text", "placeholder": "Last Name"}];

export const PopUp = ({divDisp, changePopUp}) =>{

    let stateObj ={"message": ""};

    EmailArray.map((Inp, i) =>{
        return   (stateObj[Inp.name] = "")
    })

    NameArray.map((Inp, i) =>{
        return   (stateObj[Inp.name] = "")
    })
    
    const [FormData, setFormData] = useState(stateObj);
    

    const handleChange = (e) => {
        setFormData({...FormData, [e.target.name]: e.target.value})
    }

    const ClosePopUp = () =>{
        changePopUp()
        setFormData({})
        setReply({})
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();
        if(FormData.email){
            try{
                const response = await UsersAPI.post(`/contact`, FormData)
                setReply(SubmitForm(response))
                if (response.success){
                    changePopUp(false)
                }
            } catch (error)
            {
                console.log(error);
                setReply({...reply, message: "Something didnt work, check your inputs and try again or contact us"})
            }
        } else{
            setReply({...reply, message: "Check your inputs"})
        }
    }

    const [reply, setReply] = useState({success: false, data: null, message: null})

    return(
        divDisp &&
        (<PopUpDiv>
            <ABSDiv $rgt="0" $topp="0" onClick={()=>{ClosePopUp()}}><Para $fontcolor="#059950">X</Para></ABSDiv>
            <Para>Subscribe to our Updates</Para>
            <FlexItem>
            <FlexDiv style={{width: "100%"}}>
            <FormInput handleChange={handleChange} InputArray={EmailArray} FormData={FormData} $widthe={"100%"}/>
            </FlexDiv>
            <FlexDiv style={{justifyContent: "space-between"}}>
            <FormInput handleChange={handleChange} InputArray={NameArray} FormData={FormData} $widthe={"45%"}/>
            </FlexDiv>
            </FlexItem>
            <StyledButton onClick={handleSubmit} $bgcolor="#059950">Subscribe</StyledButton>
            {reply.message  && <Para >{reply.message}</Para>}
        </PopUpDiv>
        )
    )
}