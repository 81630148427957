import styled from "styled-components";
import useWindowWidth from './useWindowWidth.js';

const PFontFactor = (width) =>{
    let factor = width > 500 ? Math.trunc(0.005*(width - 500)) : 0
    return factor
}

const ImgFactor = (width) =>{
  let factor = width > 500 ? Math.trunc(0.25*(width - 500)) : 0
  return factor
}

//-------------------------------------------------------------TEXTS---------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------

//Parent Paragraph
 export const Para = styled.p.attrs(props => {
    let wide = useWindowWidth(window.innerWidth)
     return { ...props,
        $pagewidth: wide
    }
})`
    font-size: ${({ $pagewidth, $fontsize }) => $fontsize || ($pagewidth < 500 ? "1rem" : `calc(1rem + ${2 * PFontFactor($pagewidth)}px)`)};
    color: ${({$fontcolor}) => $fontcolor || "#000000"} ;
    font-family:  Roboto;
    text-align: ${({$talign}) => $talign || "center"};
    font-style: normal;
    padding:1vh 1vw;
    margin:1vh 1vw;
    //letter-spacing:-1.1px;
    word-spacing: 3px;
    line-height:26px;
    font-weight:400;
`;

    export const RLText = styled.p.attrs(props => {
      let wide = useWindowWidth(window.innerWidth)
       return { ...props,
          $pagewidth: wide
      }
  })`
      display:inline-block;
      font-size: ${({ $pagewidth }) => $pagewidth < 500 ? "1rem" :`calc(1rem + ${2 * PFontFactor($pagewidth)}px)` }; 
      font-family:  ${({ $family }) => $family || "Open Sans"}/*Roboto*/;
      text-align: justify;
      font-style: normal;
    padding:1vh 1vw;
    margin:1vh 1vw;
    //letter-spacing:-1.1px;
     //letter-spacing: -0.015625rem;
     line-height:30px;
      //word-spacing: 3px;
  
      `;


//Main text for block sections
export const SectionText = styled(Para)`
  color:${({$fontcolor}) => $fontcolor || "#000000"} ;
  font-family: Roboto;
  font-style: normal;
  padding: 1vh 1vw;
  `;
   
export const Title = styled.h3.attrs(props => { 
  let wide = useWindowWidth(window.innerWidth)
   return { ...props,
      $pagewidth: wide
  }
})`
 font-size: ${({ $pagewidth, $fontsize }) => $fontsize || ($pagewidth < 500 ? "1.5rem" : `calc(1.5rem + ${4 * PFontFactor($pagewidth)}px)`)};
 color:${({$fontcolor}) => $fontcolor || "#000000"} ;
 text-align: center;
 text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 font-style: normal;
 line-height: normal;
 letter-spacing: 1px;
 font-family: Open Sans;
 font-weight: 600;
  `;

export const SectionTitle = styled.h4.attrs(props => { //changed from p to h2
    let wide = useWindowWidth(window.innerWidth)
     return { ...props,
        $pagewidth: wide
    }
})`
font-size: ${({ $pagewidth, $fontsize }) => $fontsize || ($pagewidth < 500 ? "1.25rem" : `calc(1.25rem + ${4 * PFontFactor($pagewidth)}px)`)};
color:${({$fontcolor}) => $fontcolor || "#000000"} ;
font-family: "Open Sans";
font-weight: 600;
text-align: center;
font-style: normal;
line-height: normal;
//letter-spacing: 1px;
align-items:center;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
text-underline-offset: 11px;
text-decoration:underline 2px solid #059950;
  `;

//Subtitle
 export const SubTitle=styled(Title).attrs(props => {
  let wide = useWindowWidth(window.innerWidth)
   return { ...props,
      $pagewidth: wide
  }
})`
  font-size: ${({ $pagewidth, $fontsize }) => $fontsize || ($pagewidth < 500 ? "1.25rem" : `calc(1.25rem + ${2 * PFontFactor($pagewidth)}px)`)};
  font-family:"Open Sans"; 
  font-weight: 700;
  text-underline-offset: 11px;
  text-decoration:underline 2px solid #059950;
  `;

// Description
    export const Description =styled.p.attrs(props =>{
      let wide = useWindowWidth(window.innerWidth)
         return { ...props,
                $pagewidth: wide
        }
      })`
    color: #000;
    text-align: justify;
    font-family: ${({$family}) => $family || "Roboto"};
    font-size:${({$pagewidth, $fontsize}) => $fontsize || ($pagewidth < 500 ? "1rem" : `calc(1rem + ${1 * PFontFactor($pagewidth)}px)`)};
    font-style: normal;
    font-weight: 400;
    margin:1vh 1vw;
    padding:1vh 1vw;
    word-wrap: wrap;
    line-height: ${({$pagewidth}) => $pagewidth < 750 ? "1.25em" : "1.5em"};
  `;  

   //Text
export const Text=styled.p.attrs(props =>{
  let wide = useWindowWidth(window.innerWidth)
     return { ...props,
            $pagewidth: wide
    }
  })`
color: ${({$pagewidth, $fontcolor}) => $fontcolor || ($pagewidth < 500 ? "#059950" : "#ffffff")};
text-align: ${({$talign}) => $talign || "center"};
font-family:${({$family}) => $family || "Open Sans"};
font-size:${({$pagewidth}) => $pagewidth < 500 ? "1rem" : `calc(1rem + ${4 * PFontFactor($pagewidth)}px)`};
font-style: normal;
font-weight: 500;
margin:${({$pagewidth}) => $pagewidth > 800 ? "2vh 2vw" : "1vh 1vw"};
padding:${({$pagewidth}) => $pagewidth > 800 ? "0 2vw" : "0"};
`;

export const ItalicText = styled(Text)`
font-style: italic;
font-weight: 400;
`;

export const TextArea = styled.textarea.attrs(props =>{
  let wide = useWindowWidth(window.innerWidth)
   return { ...props,
          $pagewidth: wide
  }
})`
  display: inline-block;
box-sizing: border-box;
border: 2px solid #059950;
border-radius: 10px;
box-shadow: 0px 2px 2px #059950;
background: rgba(0, 176, 80, 0.2);
padding: 1%;
outline: none;
margin: ${({margin})=> margin || "2.5%"};
font-size: ${({$pagewidth, $fontsize}) => $fontsize || `calc(1em + ${2*PFontFactor($pagewidth)}px)`};
color: var(--sblack);
& :focus: {
      color: var(--sblack);
      outline: none;
};
width: ${({width})=> width || "80%"};
height: ${({height})=> height || "20vh"};
font-family: ${({$family}) => $family || "var(--font-family-Open-Sans)"};
`;

//---------------------------------------------------------BUTTONS--------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------------------------
//Parent Button Component
export const StyledButton = styled.button.attrs(props =>{
  let wide = useWindowWidth(window.innerWidth)
   return { ...props,
          $pagewidth: wide
  }
})`
margin: ${({$pagewidth}) => $pagewidth < 500 ? "1vh auto" : "2vh 2vw"};
padding: ${({$pagewidth}) => $pagewidth < 500 ? "0.5vh 2vw" : "0.25vh 1vw"};
border: 4px solid ${({$bgcolor}) => $bgcolor || "#ffffff"};
color: ${({$bgcolor}) => $bgcolor ? "#ffffff" : "#059950"};
background-color: ${({$bgcolor}) => $bgcolor || "#ffffff"};
left: ${({left})=> left || "auto"};
  border-radius: 45px;
  display: block;
  font-weight: 500;
  font-family: Roboto;
  text-align: center;
  min-width: max(128px, 10vw);
  font-size: ${({$pagewidth, $fontsize}) => $fontsize || ($pagewidth < 500 ? "1rem" : `calc(1rem + ${2 * PFontFactor($pagewidth)}px)`)};;
  cursor: pointer;
`;

  //whiteButton
  export const WhiteButton=styled(StyledButton)`
  background-color:white;
 color: ${({$fontcolor}) => $fontcolor || "#059950"};
`;

//GreenButton
 export const GreenButton=styled(StyledButton)`
background-color:#059950 ;
color: ${({$fontcolor}) => $fontcolor || "white"};
 `;
//OrangeButton
export const OrangeButton=styled(StyledButton)`
background-color:#FF5F2C ;
color: ${({$fontcolor}) => $fontcolor || "white"};
 `;

//-------------------------------------------------------------------------IMAGES-------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------

 //Parent Image Component
export const Img = styled.img`
padding:1vh 1vw;
`;

export const RLImg = styled.img.attrs(props =>{
  let wide = useWindowWidth(window.innerWidth)
   return { ...props,
          $pagewidth: wide
  }
})`
 //margin: 2vh 2vw;
 width: ${({$pagewidth})=> $pagewidth < 500  ? "80vw" : `calc(250px + ${ImgFactor($pagewidth)}px)`};
 //border:2px solid #2B6F50;
 //border-radius: 1.875rem; 
 //padding:1vh 1vw;
height:${({$pagewidth}) => $pagewidth < 500 ? "auto" : "100%"}; 
// max-width: 45vw;
object-fit:cover;
margin:1vh 1vw;
`;

export const RLImg1 = styled.img.attrs(props =>{
    let wide = useWindowWidth(window.innerWidth)
     return { ...props,
            $pagewidth: wide
    }
  })`
  
   //margin: 2vh 2vw;
   width: ${({width, $pagewidth}) => width || ($pagewidth < 500 ? "90vw" : "95%") };
   //border:2px solid #2B6F50;
   //border-radius: 1.875rem; 
   //padding:1vh 1vw;
  height:${({$pagewidth}) => $pagewidth < 500 ? "auto" : "100%"}; 
  object-fit:cover;
  
    `;
//Avathar

export const AVImg =  styled.img.attrs(props =>{
  let wide = useWindowWidth(window.innerWidth)
   return { ...props,
          $pagewidth: wide
  }
})`
width: ${({width, $pagewidth}) => width || ($pagewidth < 500 ? "180px" : `calc(150px + ${30 * ImgFactor($pagewidth)}px)`)};
height:${({width, $pagewidth}) => width || ($pagewidth < 500 ? "180px" : `calc(150px + ${30 * ImgFactor($pagewidth)}px)`) };
border-radius:50%;// 10.5rem;
border:2px solid #2B6F50;
  `;

//-----------------------------------------------------------------------------------INPUT FILEDS---------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

 export const InpF = styled.input.attrs((props) =>{
  let wide = useWindowWidth(window.innerWidth)
   return { ...props,
          $pagewidth: wide
  }
})`
  font-size: ${({$fontsize, $pagewidth}) => $fontsize || `calc(1em + ${PFontFactor($pagewidth)}px)`};
  border: ${({$colour})=> $colour ? `2px solid ${$colour}`: "2px solid #059950" };
  background: ${({$colour})=>$colour || "#ffffff" };
  margin: ${({$margin, $pagewidth})=> $margin || ($pagewidth < 500 ?  "2.5%" : "1vh 1vw")};
  width: ${({$widthe, $pagewidth})=> $widthe || ($pagewidth < 800 ? "90%" : "25%")};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 1%;
  outline: none;
  text-indent: 2.5%;
  color: #000000;
  &:focus {
          box-shadow: ${({$colour})=>$colour ? `0 4px 4px 0 ${$colour}`: "0 2px 2px 0 #059950" };
          outline: none;
  };
  // font-family: ${({$family}) => $family || "Open Sans"};
`;

export const InputField=styled.input.attrs(props =>{
  let wide = useWindowWidth(window.innerWidth)
   return { ...props,
          $pagewidth: wide
  }
})`
color: #000;
text-align: center;
font-family: "Roboto";
font-size: ${({ $pagewidth }) => $pagewidth < 500 ? "1.25rem" : `calc(1.25rem + ${2 * PFontFactor($pagewidth)}px)`};
font-style: normal;
font-weight: 600;
width:25%;
border-radius: 2.5rem; // Should depend on screen width?
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);`;

export const FormInput = ({InputArray, FormData, handleChange, ...props}) =>{
  return(
      InputArray.map((Inp, i) => (
      <InpF key={i}
        placeholder={Inp.placeholder}
        name={Inp.name}
        value={FormData[Inp.name]}
        onChange ={handleChange}
        type={Inp.type}
        {...props}
    />))
  )
}
