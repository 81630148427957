
import OurStory from '../Layouts/Aboutus/OurStory.js';
import OurVision from '../Layouts/Aboutus/OurVision.js';
import OurTeam from '../Layouts/Aboutus/OurTeam.js';


const AboutusPage = () =>{
    return(
        <>
            <OurStory />
            <OurVision />
            <OurTeam />
        </>
    )}

    export default AboutusPage