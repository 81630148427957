import {SectionTitle, Text, ItalicText, StyledButton} from '../../Elements/UIElements.js';
import {BGSection, FlexItem, Section} from '../../Elements/LayoutElements.js';
import Background from '../../../Images/HWBackground.svg';
import ScanScreen from '../../../Images/ScanScreen.svg';


const SolSection = ({divDisp, changePopUp}) =>{
    const PageWidth = window.innerWidth;
    let btnColor;
    if (PageWidth <500){
        btnColor = "#059950"
    }
    return(
    <Section>
        <SectionTitle>Coming Soon</SectionTitle>
        <Text $family="Roboto" $fontcolor="#059950">An app to takeout and return reusable containers </Text>
        <BGSection $bground={Background}>
            <FlexItem>
                <img src={ScanScreen} style={{width: "20vw"}} alt='scancreen'/>
            </FlexItem>
            <FlexItem>
                <ItalicText $family="Roboto" $talign="left">Takeout food not microplastics</ItalicText>
                <StyledButton $bgcolor={btnColor} onClick={()=>changePopUp()}>Sign Up</StyledButton>
            </FlexItem>
        </BGSection>
    </Section>
)}

export default SolSection