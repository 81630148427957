import React from 'react';
import {SubSection,Section, PCard} from '../../Elements/LayoutElements.js';
import {SectionTitle} from '../../Elements/UIElements.js';
import PhoneScan from  '../../../Images/PhoneScan.svg';
import CoffeeatWork from '../../../Images/CoffeeatWork.svg';
import CoffeePurchase from  '../../../Images/CoffeePurchase.svg';

const HWSection = () =>{
    return(
         <Section>
            <SectionTitle>How it Works</SectionTitle>
            <SubSection>
              <PCard CardImg={PhoneScan} ImgAlt="Phonescan" CardTxt="Using the app tap on the container" CardTitle="Tap" />
              <PCard CardImg={CoffeeatWork} ImgAlt="CoffeeatWork" CardTxt="Enjoy your takeout food or drink" CardTitle="Enjoy"/>
              <PCard CardImg={CoffeePurchase} ImgAlt="CoffeePurchase" CardTxt="Return at home or bring it back" CardTitle="Return"/>
           </SubSection>
        </Section>
    )   
}

export default HWSection