const SubmitForm = (APIResponse) => {
    let success, data, message;
    console.log(APIResponse);
    if (APIResponse.status){
        if (APIResponse.status >= 200){
            data = APIResponse.data;
            success = true;
            message = "Your request is successfully submitted"
        } else {
            success = true;
            message = "Your request submitted, please wait until it is processed"
        }        
    } else if (APIResponse.response.request.status >= 500){
        success= false;
        data = null;
        message = "Server Error, please try again, if the error persists, call us at +1 832 974 0050";
    } else if (APIResponse.response.request.status >= 400) {
        success = false;
        data = null;
        message = "Please check your inputs and try again";
    } else {
        success = false;
        message = "Please wait...";
    }
     return { success: success,
        data: data,
        message: message
    }
}

export default SubmitForm